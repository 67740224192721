import { ErrorMessage, Field, Formik, Form as FormikForm } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import * as Yup from 'yup';
import { Axios } from '../../config';
import useTranslateTexts from '../../Context/useTranslateTexts';

// Schema de validation
const validationSchema = Yup.object({
    email: Yup.string()
        .email('Adresse email invalide')
        .required('Le champ email est obligatoire'),
});

export default function ResetPassword({ setMethod, setResetMethods }) {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation()
    const [showAlertError, setShowAlertError] = useState(null);
    const [showAlertSuccess, setShowAlertSuccess] = useState(null);
    const normalizeLanguageCode = (code) => {
        return code.split('-')[0]; // Prend la première partie avant le tiret
    };
    const languageCode = normalizeLanguageCode(i18n.language);
    const translatedTexts = useTranslateTexts({
        frg: "Password Reset",
        btn: 'Login',
        em: 'Email Verification',
        env: ' Envoyer le lien de réinitialisation',
        emm: 'E-mail',
        plc: 'Enter your email',
        prag: 'Enter the e-mail address you used when you joined and we’ll send you instructions to reset your password.',
        err:'If an account is associated with this email address, you will receive a password reset email shortly'
    });


    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        try {
            const res = await Axios.get("/user/getResetMethods", { params: { email: values.email } });

            setResetMethods(res.data)
            if (!res.data?.nbBackupCodes && !res.data?.otpPhoneEnabled && !res.data?.recoveryEmail && !res.data?.totpEnabled) {
                setMethod("email")
            }
        } catch (err) {
            if (err?.response?.data?.msg === "If an account is associated with this email address, you will receive a password reset email shortly.") {
                setShowAlertError(translatedTexts.err)
            } else {
                setShowAlertError(err?.response?.data?.msg);
            }
        }

        setSubmitting(false);

    }

    return (
        <div>
            {showAlertSuccess && (
                <div className="alert alert-success">{showAlertSuccess}</div>
            )}
            {showAlertError && (
                <div className="alert alert-danger">{showAlertError}</div>
            )}
            <h1>{translatedTexts.frg}</h1>
            <Formik
                initialValues={{ email: '' }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <FormikForm>
                        <p className='text-center'>{translatedTexts?.prag}</p>

                        <FormGroup>
                            <Label for="email">{translatedTexts.emm}</Label>
                            <Field
                                name="email"
                                as={Input} // Utilisation de l'élément Input de Reactstrap
                                type="email"
                                placeholder={translatedTexts?.plc}
                                className="form-control"
                            />
                            <ErrorMessage name="email" component="div" className="text-danger" />
                        </FormGroup>

                        <Button type="submit" color="primary" disabled={isSubmitting}>
                            {translatedTexts.env}
                        </Button>
                    </FormikForm>
                )}
            </Formik>
        </div>
    );
}

ResetPassword.propTypes = {
    setMethod: PropTypes.func,
    setResetMethods: PropTypes.func,
}