import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '../../../config';

// Mise à jour de la fonction pour inclure tous les filtres
export const fetchLog = createAsyncThunk('log/fetchLog', async ({ userRole, siteId, page, limit, startDate, endDate }, { rejectWithValue }) => {
  try {
    let url = `/log/logEntry?page=${page}&startDate=${startDate}&endDate=${endDate}&userRole=${userRole}`;

    if (userRole === 'Client' && siteId) {
      // If the user is a Client, siteId is required to be added
      url += `&siteId=${siteId}`;
    } else if (userRole === 'Client' && !siteId) {
      // Reject the promise if the siteId is missing for a client
      return rejectWithValue('SiteId is required for client role.');
    }
    let res = await Axios.get(url);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});
export const fetchLogReport = createAsyncThunk('log/fetchLogReport', async ({ userRole, siteId, page, timeRange, limit, startDate, endDate }, { rejectWithValue }) => {
  try {
    let url = `/log/logEntryAgregate?startDate=${startDate}&endDate=${endDate}&userRole=${userRole}`;

    if (userRole === 'Client' && siteId) {
      // Ajouter siteId si l'utilisateur est un Client
      url += `&siteId=${siteId}`;
    } else if (userRole === 'Client' && !siteId) {
      // Rejeter si siteId est manquant pour un client
      return rejectWithValue('SiteId is required for client role.');
    }

    // Toujours ajouter timeRange si défini, peu importe le rôle de l'utilisateur
    if (timeRange) {
      url += `&timeRange=${timeRange}`;
    }

    let res = await Axios.get(url);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});

export const fetchLogReportAdmin = createAsyncThunk('log/fetchLogReportAdmin', async ({ userRole, siteId, page, timeRange, limit, startDate, endDate }, { rejectWithValue }) => {
  try {
    let url = `/log/logEntryAgregateAdmin?startDate=${startDate}&endDate=${endDate}&userRole=${userRole}`;

    if (siteId) {
      // Ajouter siteId si présent
      url += `&siteId=${siteId}`;
    }

    // Toujours ajouter timeRange si défini
    if (timeRange) {
      url += `&timeRange=${timeRange}`;
    }

    let res = await Axios.get(url);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});

export const fetchQuery = createAsyncThunk('log/fetchQuery', async ({ userRole, siteId, page, timeRange, limit, startDate, endDate }, { rejectWithValue }) => {
  try {
    let url = `/log/query?startDate=${startDate}&endDate=${endDate}`;
    if (userRole === 'Client' && siteId) {
      // If the user is a Client, siteId is required to be added
      url += `&siteId=${siteId}`;
    } else if (userRole === 'Client' && !siteId) {
      // Reject the promise if the siteId is missing for a client
      return rejectWithValue('SiteId is required for client role.');
    }
    let res = await Axios.get(url);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});
export const fetchQueryAdmin = createAsyncThunk('log/fetchQueryAdmin', async ({ userRole, siteId, page, timeRange, limit, startDate, endDate }, { rejectWithValue }) => {
  try {
    let url = `/log/queryAdmin?startDate=${startDate}&endDate=${endDate}&userRole=${userRole}`;

    if (userRole === 'Admin' && siteId) {
      // If the user is a Client, siteId is required to be added
      url += `&siteId=${siteId}`;}
    let res = await Axios.get(url);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});
export const fetchLogAdmin = createAsyncThunk('log/fetchLogAdmin', async ({ userRole, siteId, page, limit, startDate, endDate,domain,country,type }, { rejectWithValue }) => {
  try {
    let url = `/log/logEntryAdmin?page=${page}&limit=${limit}&type=${type}&domain=${domain}&country=${country}&startDate=${startDate}&endDate=${endDate}&userRole=${userRole}`;
    if (userRole === 'Admin' && siteId) {
      url += `&siteId=${siteId}`;
    }


    let res = await Axios.get(url);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});
export const fetchLogIaAdmin = createAsyncThunk('log/fetchLogIaAdmin', async ({ userRole, siteId, page, limit, startDate, endDate }, { rejectWithValue }) => {
  try {
    let url = `/log/logEntryIaAdmin?page=${page}&startDate=${startDate}&endDate=${endDate}&userRole=${userRole}`;
    if (userRole === 'Admin' && siteId) {
      url += `&siteId=${siteId}`;
      console.log('url', url)
    }
    let res = await Axios.get(url);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});


export const fetchLogIA = createAsyncThunk('log/fetchLogIa', async ({ userRole, siteId, page, startDate, endDate }, { rejectWithValue }) => {
  try {
    let url = `/log/logEntryIa?page=${page}&startDate=${startDate}&endDate=${endDate}&userRole=${userRole}`;

    if (userRole === 'Client' && siteId) {
      // If the user is a Client, siteId is required to be added
      url += `&siteId=${siteId}`;
    } else if (userRole === 'Client' && !siteId) {
      // Reject the promise if the siteId is missing for a client
      return rejectWithValue('SiteId is required for client role.');
    }
    let res = await Axios.get(url);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});
export const deleteLog = createAsyncThunk('log/deleteLog', async (siteId) => {
  try {
    let res = await Axios.delete(`/log/clearLog?siteId=${siteId}`);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});
export const sendEmailWithAttachment = createAsyncThunk ('emailwithpdf/email', async (email, translatedText, pdfBase64) => {
  try {
    const response = await Axios.post(`/email/send-email`, {
      email: email,  // Email of the recipient
      subject: translatedText?.sub,  // Subject of the email
      message: translatedText?.message,  // Body of the email
      pdfBase64: pdfBase64  // Base64-encoded PDF data
    });

    return response.data;

  } catch (error) {
    console.error('Error sending email', error);
  }
});


const initialState = {
  isSuccessLog: false,
  isErrorLog: false,
  isFetchingLog: false,
  isFetching: false,
  isFetchingLogIA: false,
  isFetchingLogAdmin: false,
  isSuccessLogAdmin: false,
  isErrorLogAdmin: false,
  logAdmin: [], // initialiser logadmin comme un tableau vide
  isSuccessLogIa: false,
  isErrorLogIa: false,
  logIa: [],
  messageError: '',
  log: [], // initialiser log comme un tableau vide
  isSuccess: false,
  isSuccessLogIaAdmin: false,
  logReport: [],
  isSuccessLogReport: false,
  isErrorLogReport: false,
  isFetchingLogReport: false,
  logReportAdmin: [],
  isSuccessLogReportAdmin: false,
  isErrorLogReportAdmin: false,
  isFetchingLogReportAdmin: false,


  isErrorLogIaAdmin: false,
  isFetchingLogIaAdmin: false,

  logIaAdmin: [],
  isSuccessQuery: false,
  isErrorQuery: false,
  isFetchingQuery: false,
  query: [],
  isSuccessQueryAdmin: false,
  isErrorQueryAdmin: false,
  isFetchingQueryAdmin: false,
  queryAdmin: [],
  
}
export const logEntrySlice = createSlice({
  name: 'log',
  initialState,
  reducers: { clearLogsData: () => initialState },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLog.pending, (state) => {
        state.isFetchingLog = true;
        // state.count=0;
        state.isErrorLog = false;
        state.log = [];
        state.logIa = [];
        state.isSuccessLog = false;
      })

      .addCase(fetchLog.fulfilled, (state, { payload }) => {
        state.isFetchingLog = false;
        state.log = payload;
        state.isSuccessLog = true;
        state.isErrorLog = false
      })
      .addCase(fetchLog.rejected, (state, { payload }) => {
        state.isFetchingLog = false;
        state.isErrorLog = true;
        state.isSuccessLog = false;
        // state.messageError=payload.message
      })
      .addCase(fetchLogReport.pending, (state) => {
        state.isFetchingLogReport = true;
        // state.count=0;
        state.isErrorLogReport = false;
       
        state.isSuccessLogReport = false;})
    
      .addCase(fetchLogReport.fulfilled, (state, { payload }) => {
        state.isFetchingLogReport = false;
        state.logReport = payload;
       state.isSuccessLogReport=true;
       state.isErrorLogReport=false
        })
        .addCase(fetchLogReport.rejected, (state, { payload }) => {
          state.isFetchingLogReport = false;
          state.isErrorLogReport = true;
          state.isSuccessLogReport = false;
          // state.messageError=payload.message
        })
      .addCase(fetchLogReportAdmin.pending, (state) => {
        state.isFetchingLogReportAdmin = true;
        // state.count=0;
        state.isErrorLogReportAdmin = false;
       
        state.isSuccessLogReportAdmin = false;})
    
      .addCase(fetchLogReportAdmin.fulfilled, (state, { payload }) => {
        state.isFetchingLogReportAdmin = false;
        state.logReportAdmin= payload;
       state.isSuccessLogReportAdmin=true;
       state.isErrorLogReportAdmin=false
        })
        .addCase(fetchLogReportAdmin.rejected, (state, { payload }) => {
          state.isFetchingLogReportAdmin = false;
          state.isErrorLogReportAdmin = true;
          state.isSuccessLogReportAdmin = false;
          // state.messageError=payload.message
        })
      .addCase(fetchQuery.pending, (state) => {
        state.isFetchingQuery = true;
        // state.count=0;
        state.isErrorQuery = false;

        state.isSuccessQuery = false;
      })

      .addCase(fetchQuery.fulfilled, (state, { payload }) => {
        state.isFetchingQuery = false;
        state.query = payload;
        state.isSuccessQuery = true;
        state.isErrorQuery = false
      })
      .addCase(fetchQuery.rejected, (state, { payload }) => {
        state.isFetchingQuery = false;
        state.isErrorQuery = true;
        state.isSuccessQuery = false;
        // state.messageError=payload.message
      })
      .addCase(fetchQueryAdmin.pending, (state) => {
        state.isFetchingQueryAdmin = true;
        // state.count=0;
        state.isErrorQueryAdmin = false;

        state.isSuccessQueryAdmin = false;
      })

      .addCase(fetchQueryAdmin.fulfilled, (state, { payload }) => {
        state.isFetchingQueryAdmin = false;
        state.queryAdmin = payload;
        state.isSuccessQueryAdmin = true;
        state.isErrorQueryAdmin = false
      })
      .addCase(fetchQueryAdmin.rejected, (state, { payload }) => {
        state.isFetchingQueryAdmin = false;
        state.isErrorQueryAdmin = true;
        state.isSuccessQueryAdmin = false;
        // state.messageError=payload.message
      })
      .addCase(fetchLogAdmin.pending, (state) => {
        state.isFetchingLogAdmin = true;
        // state.count=0;
        state.isErrorLogAdmin = false;
        state.logAdmin = [];
        state.logIa = [];
        state.isSuccessLogAdmin = false;
      })

      .addCase(fetchLogAdmin.fulfilled, (state, { payload }) => {
        state.isFetchingLogAdmin = false;
        state.logAdmin = payload;
        state.totalPages = payload.pages;  // Assurez-vous de stocker `totalPages` reçu.

        state.isSuccessLogAdmin = true;
        state.isErrorLogAdmin = false
      })
      .addCase(fetchLogAdmin.rejected, (state, { payload }) => {
        state.isFetchingLogAdmin = false;
        state.isErrorLogAdmin = true;
        state.isSuccessLogAdmin = false;
        // state.messageError=payload.message
      })
      .addCase(fetchLogIA.pending, (state) => {
        state.isFetchingLogIA = true;
        state.logIa = [];
        state.isErrorLogIa = false;
        state.isSuccessLogIa = false;
      })

      .addCase(fetchLogIA.fulfilled, (state, { payload }) => {
        state.isFetchingLogIA = false;
        state.logIa = payload;
        state.isSuccessLogIa = true;
        state.isErrorLogIa = false
      })
      .addCase(fetchLogIA.rejected, (state, { payload }) => {
        state.isFetchingLogIA = false;
        state.isErrorLogIa = true;
        state.isSuccessLogIa = false;
        // state.errorlogIa=payload.error.message
        // state.messageError=payload.message
      })
      .addCase(fetchLogIaAdmin.pending, (state) => {
        state.isFetchingLogIaAdmin = true;
        state.logIaAdmin = [];
        state.isErrorLogIaAdmin = false;
        state.isSuccessLogIaAdmin = false;
      })

      .addCase(fetchLogIaAdmin.fulfilled, (state, { payload }) => {
        state.isFetchingLogIaAdmin = false;
        state.logIaAdmin = payload;
        state.isSuccessLogIaAdmin = true;
        state.isErrorLogIaAdmin = false
      })
      .addCase(fetchLogIaAdmin.rejected, (state, { payload }) => {
        state.isFetchingLogIA = false;
        state.isErrorLogIaAdmin = true;
        state.isSuccessLogIaAdmin = false
        // state.errorlogIa=payload.error.message
        // state.messageError=payload.message
      })
      .addCase(deleteLog.pending, (state) => {
        state.isFetching = true;
        // state.count=0;
        state.isError = false;
        state.isSuccess = false;
      })

      .addCase(deleteLog.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.log = payload;
        state.isSuccess = true;
        state.isError = false
      })
      .addCase(deleteLog.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.messageError = payload.message
      })


  }
})

export const { clearLogsData } = logEntrySlice.actions;

export default logEntrySlice.reducer;